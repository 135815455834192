import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faTimes,
  faTachometer,
  faEgg,
  faLayerGroup,
  faCog,
  faBallPile,
  faMicrochip,
  faChevronDown,
  faPowerOff,
  faRedoAlt,
  faCheck,
  faCalendarDay,
  faCheckCircle,
  faTimesCircle,
  faEdit,
  faSyncAlt,
  faTrash,
  faImages,
  faDownload,
  faFileCsv,
  faBoxes,
  faPlay,
  faRedo,
  faPlus,
  faBell,
  faChartLine,
  faCheckSquare,
  faSquare,
  faSignalStream,
  faToggleOn,
  faToggleOff,
  faSpinnerThird
} from '@fortawesome/pro-solid-svg-icons';

import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faBars,
  faTimes,
  faTachometer,
  faEgg,
  faLayerGroup,
  faCog,
  faBallPile,
  faMicrochip,
  faChevronDown,
  faPowerOff,
  faRedoAlt,
  faCheck,
  faCalendarDay,
  faCheckCircle,
  faTimesCircle,
  faEdit,
  faSyncAlt,
  faTrash,
  faImages,
  faDownload,
  faFileCsv,
  faBoxes,
  faPlay,
  faRedo,
  faPlus,
  faBell,
  faChartLine,
  faCheckSquare,
  faSquare,
  faSignalStream,
  faToggleOn,
  faToggleOff,
  faSpinnerThird,

  // Duo Tone
  faExclamationTriangle
);
Vue.component('fa-icon', FontAwesomeIcon);

dom.watch();
