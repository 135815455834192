<template>
  <div class="p-8 border rounded bg-white shadow-inner mt-4 text-center">
    <fa-icon
      :icon="['fad', 'exclamation-triangle']"
      class="text-5xl text-yellow-400 fa-swap-opacity"
    />
    <div class="text-xl text-gray-700 mt-4">Implementation pending!</div>
  </div>
</template>

<script>
export default {};
</script>
