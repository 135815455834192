<template>
  <div class="flex flex-col items-center">
    <h1 class="font-bold text-lg mb-4">Set new password</h1>
    <change-password
      class="md:w-1/2 lg:w-1/4 xl:w-1/5 bg-white p-4 shadow-md rounded"
      @done="$router.push({ name: 'Dashboard' })"
    />
  </div>
</template>

<script>
import ChangePassword from '@/components/user/ChangePassword';
export default {
  components: { ChangePassword }
};
</script>
