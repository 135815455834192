export default [
  {
    path: '/mating',
    component: () =>
      import(/* webpackChunkName: "mating" */ '@/views/mating/index.vue'),
    children: [
      {
        path: '',
        name: 'MatingOverview',
        component: () =>
          import(/* webpackChunkName: "mating" */ '@/views/mating/Overview.vue')
      },
      {
        path: 'egg-batches',
        name: 'EggBatches',
        component: () =>
          import(
            /* webpackChunkName: "mating" */ '@/views/mating/EggBatches.vue'
          )
      },
      {
        path: 'fly-rooms',
        name: 'FlyRooms',
        component: () =>
          import(/* webpackChunkName: "mating" */ '@/views/mating/FlyRooms.vue')
      }
      // {
      //   path: 'harvesting',
      //   name: 'EggHarvesting',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "eggs" */ '@/views/eggs/EggHarvesting.vue'
      //     )
      // }
    ]
  }
];
