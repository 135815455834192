import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

export default {
  install(Vue, options = {}) {
    Vue.prototype.$echo = new Echo(
      Object.assign(
        {
          broadcaster: 'pusher',
          key: process.env.VUE_APP_PUSHER_APP_KEY,
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          wsHost: window.location.hostname,
          wsPort: process.env.NODE_ENV === 'production' ? 6002 : 6001,
          wssPort: process.env.NODE_ENV === 'production' ? 6002 : 6001,
          enabledTransports: ['ws', 'wss'],
          enableStats: false,
          forceTLS: process.env.NODE_ENV === 'production',
          authorizer: channel => {
            return {
              authorize: async (socketId, callback) => {
                options
                  .axios({
                    url: '/broadcasting/auth',
                    baseURL: '/',
                    method: 'post',
                    data: {
                      socket_id: socketId,
                      channel_name: channel.name
                    }
                  })
                  .then(response => {
                    callback(false, response.data);
                  })
                  .catch(error => {
                    callback(true, error);
                  });
              }
            };
          }
        },
        options
      )
    );
  }
};
