<template>
  <div class="relative bg-white">
    <label
      class="absolute rounded px-2 text-gray-600 inline-block bg-white input-label pointer-events-none"
      :class="classes"
    >
      {{ label }}
    </label>
    <v-select
      :value="value"
      :options="allOptions"
      :label="optionLabel"
      v-bind="props"
      :reduce="reduce"
      :multiple="multiple"
      :clearable="clearable"
      :clear-search-on-select="clearSearchOnSelect"
      :close-on-select="closeOnSelect"
      @search="search"
      @input="onInput"
      @search:focus="focused = true"
      @search:blur="focused = false"
      class="p-0"
    >
      <template v-slot:option="option">
        <slot v-bind:option="option"></slot>
      </template>
    </v-select>
    <p v-if="error" class="text-xs text-red-500 mt-1">{{ error }}</p>
  </div>
</template>

<script>
import qs from 'qs';
import isEmpty from 'lodash.isempty';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { handleFailedResponse } from '@/core/helpers';

export default {
  components: { VSelect },
  props: {
    value: {
      validator: value => {
        return (
          ['number', 'object', 'string'].includes(typeof value) ||
          value === null ||
          value === undefined
        );
      },
      required: true
    },
    fixedOptions: {
      type: Array,
      default: () => []
    },
    url: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    optionLabel: {
      type: String,
      default: 'name'
    },
    error: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Select option'
    },
    search: {
      type: Function,
      default: () => {}
    },
    reduce: {
      type: Function,
      default: option => option
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selectedOption: {
      type: Object,
      default: null
    },
    selectedOptions: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: true
    },
    clearSearchOnSelect: {
      type: Boolean,
      default: true
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Array,
      default: () => []
    },
    getOptionLabel: {
      type: Function,
      default: null
    },
    moveLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      focused: false,
      options: []
    };
  },
  created() {
    this.fetchOptions();
  },
  computed: {
    props() {
      let props = {};

      if (this.getOptionLabel) {
        props = {
          getOptionLabel: this.getOptionLabel
        };
      }

      return props;
    },
    labelAbove() {
      return !!this.value || this.value === 0;
    },
    classes() {
      if (!this.moveLabel) {
        return this.value ? 'hidden' : 'p-3 text-sm z-10 w-full h-auto';
      }

      return this.labelAbove || this.focused
        ? '-mt-2 ml-3 text-xs z-10'
        : 'p-2 m-1 text-sm z-10 h-auto w-full';
    },
    allOptions() {
      if (isEmpty(this.selectedOption)) {
        return this.options;
      }
      if (this.alreadyContainsOption(this.selectedOption)) {
        return this.options;
      }
      return [this.selectedOption, ...this.options];
    }
  },
  methods: {
    async fetchOptions() {
      if (!isEmpty(this.fixedOptions)) {
        this.options = this.fixedOptions;
        return;
      }

      try {
        const { data } = await this.axios.get(this.url, {
          params: {
            filter: this.filter
          },
          paramsSerializer: params => qs.stringify(params)
        });

        this.options = data.data;
      } catch (e) {
        handleFailedResponse(e);
      }
    },
    onInput(value) {
      this.$emit('input', value);
      this.$emit('change');
    },
    alreadyContainsOption(selectedOption) {
      return !isEmpty(
        this.options.filter(option => option.id === selectedOption.id)
      );
    }
  }
};
</script>

<style scoped>
.input-label {
  transition: 0.3s all;
}
</style>
