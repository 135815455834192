import Vue from 'vue';
import AppLogo from '@/components/global/app/AppLogo';
import ModalDialog from '@/components/global/ModalDialog';
import InputField from '@/components/global/form/InputField';
import SelectField from '@/components/global/form/SelectField';
import PrimaryButton from '@/components/global/form/PrimaryButton';
import SectionLabel from '@/components/global/form/SectionLabel';
import ConfirmActionDialog from '@/components/global/ConfirmActionDialog';
import ImplementationPending from '@/components/global/ImplementationPending';
import SectionTitle from '@/components/global/section/SectionTitle';

Vue.component('app-logo', AppLogo);
Vue.component('modal-dialog', ModalDialog);
Vue.component('input-field', InputField);
Vue.component('select-field', SelectField);
Vue.component('primary-button', PrimaryButton);
Vue.component('section-label', SectionLabel);
Vue.component('confirm-action-dialog', ConfirmActionDialog);
Vue.component('implementation-pending', ImplementationPending);
Vue.component('section-title', SectionTitle);
