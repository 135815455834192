<template>
  <modal-dialog :show="show" :width="width" @close="close">
    <h1 class="text-2xl font-bold mb-4">
      <slot name="title">Are you sure you want to proceed?</slot>
    </h1>
    <slot></slot>
    <div class="flex justify-end mt-4">
      <button @click="close" class="px-2 py-1 mr-4">
        Close
      </button>
      <primary-button
        :loading="processing"
        :classes="_options.proceedButtonClasses"
        @click="proceed"
      >
        Proceed
      </primary-button>
    </div>
  </modal-dialog>
</template>

<script>
import { handleFailedResponse } from '@/core/helpers';

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    resource: {
      type: Object,
      default: null
    },
    width: {
      type: [Number, String],
      default: 500
    },
    type: {
      type: String,
      default: null
    },
    action: {
      type: Function,
      required: true
    },
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false
    };
  },
  computed: {
    _options() {
      const options = {
        proceedButtonClasses:
          'bg-red-600 hover:bg-red-700 active:bg-red-500 px-4 w-32'
      };

      if (!this.options) {
        return options;
      }

      return Object.assign(options, this.options);
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    proceed() {
      if (this.type === 'delete') {
        this.performDeleteAction();
        return;
      }
      this.performAction();
    },
    async performAction() {
      this.processing = true;
      try {
        await this.action();
        this.$emit('done');
        this.close();
      } catch (e) {
        handleFailedResponse(e);
      } finally {
        this.processing = false;
      }
    },
    async performDeleteAction() {
      this.processing = true;
      try {
        await this.action(this.resource.id);
        this.$emit('done');
        this.close();
      } catch (e) {
        handleFailedResponse(e);
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
