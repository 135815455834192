export default [
  {
    path: '/hatching',
    component: () =>
      import(/* webpackChunkName: "hatching" */ '@/views/hatching/index.vue'),
    children: [
      {
        path: '',
        name: 'Hatching',
        component: () =>
          import(
            /* webpackChunkName: "hatching" */ '@/views/hatching/HatchingOverview.vue'
          )
      },
      {
        path: 'completed-processes',
        name: 'CompletedProcesses',
        component: () =>
          import(
            /* webpackChunkName: "hatching" */ '@/views/hatching/CompletedProcesses.vue'
          )
      },
      {
        path: 'feeding-boxes',
        name: 'FeedingBoxes',
        component: () =>
          import(/* webpackChunkName: "hatching" */ '@/views/FeedingBoxes.vue')
      }
    ]
  }
];
