<template>
  <fetch-json url="/locations" disable-pagination>
    <template v-slot:default="{ response: locations }">
      <div class="text-lg font-bold mb-4">Climate Control</div>
      <sensor-reading-chart
        v-for="location in locations"
        :key="location.id"
        :location="location"
        horizontal
        standalone
        refresh
        default-interval="last-3-hours"
        class="mb-4"
      />
    </template>
  </fetch-json>
</template>

<script>
import FetchJson from '../global/FetchJson';
import SensorReadingChart from './SensorReadingChart';
export default {
  components: { SensorReadingChart, FetchJson }
};
</script>
