import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: {
      user: null,
      loggedIn: false
    }
  },
  mutations: {
    SET_USER(state, { user }) {
      state.auth.user = user;
      state.auth.loggedIn = true;
    },
    LOGOUT(state) {
      state.auth.user = null;
      state.auth.loggedIn = false;
    },
    TEMPORARY_PASSWORD_CHANGED(state) {
      state.auth.user.has_system_generated_password = false;
    }
  },
  actions: {},
  modules: {}
});
