<template>
  <div class="flex flex-col justify-center items-center min-h-full">
    <app-logo class="h-32 mb-8 -mt-8" />
    <div class="bg-white p-8 shadow rounded-lg sm:w-1/2 md:w-1/3 lg:w-1/4">
      <h1 class="text-center text-xl font-bold mb-6">Welcome Back!</h1>
      <form @submit.prevent="submit">
        <input-field
          v-model="form.email"
          label="Email"
          :error="form.errors.get('email')"
          class="mb-6"
        />
        <input-field
          v-model="form.password"
          type="password"
          label="Password"
          :error="form.errors.get('password')"
          class="mb-6"
        />
        <primary-button :loading="processing" class="w-full">
          Login
        </primary-button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      processing: false,
      form: this.$form({
        email: null,
        password: null
      })
    };
  },
  methods: {
    async submit() {
      this.processing = true;
      await this.axios({ url: '/sanctum/csrf-cookie', baseURL: '/' });

      try {
        await this.form.submit('post', '/login', { baseURL: '/' });

        await this.$router.push({ name: 'Dashboard' });
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
