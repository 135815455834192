<template>
  <div
    class="lg:text-base cursor-pointer"
    :class="{
      'lg:relative': !isSubmenuOpen,
      'lg:inline-block': !isSubmenu
    }"
    @click.stop="navigate"
    @mouseleave.stop="close"
  >
    <div
      class="flex justify-between items-center rounded mt-1 md:mt-0 px-2 py-2 transition-all duration-300 hover:bg-primary-100 hover:text-primary-400"
      @mouseenter.stop="open"
    >
      <router-link
        :to="link.path"
        class="lg:mr-1"
        :class="{
          'active-link': isSubmenu
            ? $route.path === link.path
            : $route.matched.map(route => route.path).includes(link.path)
        }"
        @click.native="$emit('close')"
      >
        <fa-icon
          v-if="link.icon"
          :icon="link.icon"
          class="text-gray-400 opacity-75 mr-1 fa-fw sm:hidden lg:inline-block"
        />
        {{ link.name }}
      </router-link>
      <button
        v-if="link.submenus"
        class="text-gray-500 opacity-75 focus:outline-none px-2"
        @click.stop="isSubmenuOpen = !isSubmenuOpen"
      >
        <fa-icon
          :icon="['fas', 'chevron-down']"
          class="fa-fw transition-all duration-300 lg:text-sm"
          :class="{ 'fa-rotate-180': isSubmenuOpen }"
        />
      </button>
    </div>
    <div
      v-if="isSubmenuOpen && !isSubmenu"
      class="ml-8 lg:ml-0 lg:absolute lg:bg-white md:border md:shadow rounded z-30 lg:p-4"
    >
      <navbar-link
        v-for="submenu in submenus"
        :key="submenu.name"
        :link="submenu"
        is-submenu
        @close="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavbarLink',
  props: {
    link: {
      type: Object,
      required: true
    },
    isSubmenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSubmenuOpen: false
    };
  },
  computed: {
    submenus() {
      if (this.$store.state.auth.user.is_super_admin) {
        return this.link.submenus;
      }

      return this.link.submenus.filter(link => link?.superAdmin !== true);
    }
  },
  methods: {
    navigate() {
      if (this.$route.path !== this.link.path) {
        this.$router.push(this.link.path);
      }
      this.close();
      this.$emit('close');
    },
    open() {
      if (!this.link.submenus) {
        return;
      }

      this.isSubmenuOpen = true;
    },
    close() {
      this.isSubmenuOpen = false;
    }
  }
};
</script>
