<template>
  <form @submit.prevent="submit">
    <input-field
      v-model="form.current_password"
      label="Current Password"
      type="password"
      :error="form.errors.get('current_password')"
      class="mb-4"
    />
    <input-field
      v-model="form.password"
      label="New Password"
      type="password"
      :error="form.errors.get('password')"
      class="mb-4"
    />
    <input-field
      v-model="form.password_confirmation"
      label="Confirm Password"
      type="password"
      :error="form.errors.get('password_confirmation')"
      class="mb-4"
    />
    <primary-button :loading="processing" class="w-full">
      Change Password
    </primary-button>
  </form>
</template>

<script>
import { handleFailedResponse } from '@/core/helpers';

export default {
  data() {
    return {
      processing: false,
      form: this.$form({
        current_password: null,
        password: null,
        password_confirmation: null
      })
    };
  },
  methods: {
    async submit() {
      this.processing = true;
      try {
        await this.form.submit('patch', '/change-password');
        this.$store.commit('TEMPORARY_PASSWORD_CHANGED');
        this.$emit('done');
        this.form.reset();
      } catch (e) {
        console.log(e);
        handleFailedResponse(e);
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
