<template>
  <div class="flex flex-col justify-between bg-white shadow-md rounded p-4">
    <label class="text-gray-600 mb-4 uppercase text-sm">{{ label }}</label>
    <div
      class="relative leading-none text-4xl font-bold text-right text-gray-900"
    >
      <fa-icon
        :icon="[iconStyle, icon]"
        class="absolute left-0 bottom-0 text-3xl text-gray-400"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    iconStyle: {
      type: String,
      default: 'fas'
    },
    icon: {
      type: String,
      required: true
    }
  }
};
</script>
