<template>
  <portal to="modals" v-if="show">
    <div class="modal-backdrop" v-show="show">
      <div class="flex justify-center items-center">
        <div
          class="modal"
          :style="{ width: fullWidth ? '100%' : width + 'px' }"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: 500
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    dismiss() {
      this.$emit('close');
    }
  },
  watch: {
    show: {
      immediate: true,
      handler: show => {
        if (show) {
          document.body.style.setProperty('overflow', 'hidden');
        } else {
          document.body.style.removeProperty('overflow');
        }
      }
    }
  },
  created() {
    const escapeHandler = e => {
      if (e.key === 'Escape' && this.show) {
        this.dismiss();
      }
    };
    document.addEventListener('keydown', escapeHandler);
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler);
    });
  }
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  padding-top: 4rem;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 2rem;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
</style>
