import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Login from '@/views/Login';
import Dashboard from '@/views/Dashboard.vue';
import mating from './mating';
import hatching from '@/router/hatching';
import breeding from '@/router/breeding';
import harvesting from '@/router/harvesting';
import settings from './settings';
import axios from 'axios';
import SetPassword from '@/views/SetPassword';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/set-password',
    name: 'SetPassword',
    component: SetPassword
  },
  ...mating,
  ...hatching,
  ...breeding,
  ...harvesting,
  ...settings,
  {
    path: '/web-cart',
    name: 'WebCart',
    component: () =>
      import(/* webpackChunkName: "web-cart" */ '@/views/WebCart.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: null,
  routes
});

router.beforeEach((to, from, next) => {
  const auth = store.state.auth;

  if (to.name !== 'Login' && auth.loggedIn) {
    if (auth.user.has_system_generated_password && to.name !== 'SetPassword') {
      next({ name: 'SetPassword' });
      return;
    }

    if (to?.meta?.superAdmin && auth.user.is_super_admin !== true) {
      next(false);
      return;
    }

    next();
    return;
  }

  axios
    .get('/user')
    .then(({ data }) => {
      store.commit('SET_USER', { user: data.data });

      if (
        auth.user.has_system_generated_password &&
        to.name !== 'SetPassword'
      ) {
        next({ name: 'SetPassword' });
        return;
      }

      if (to?.meta?.superAdmin && auth.user.is_super_admin !== true) {
        next(false);
        return;
      }

      if (to.name === 'Login' && auth.loggedIn) {
        next({ name: 'Dashboard' });
        return;
      }

      if (to.name !== 'Login' && !auth.loggedIn) {
        next({ name: 'Login' });
        return;
      }

      next();
    })
    .catch(() => {
      if (to.name === 'Login') next();
      else next({ name: 'Login' });
    });
});

export default router;
