<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="flex items-center">
        <h1 class="font-bold mr-8">{{ location.name }}</h1>
        <select-field
          v-model="interval"
          label="Interval"
          :reduce="option => option.value"
          :fixed-options="intervals"
          class="w-64 shadow-md bg-white rounded mr-4"
        />
        <div>
          <input v-model="autoRefresh" type="checkbox" class="mr-2" />
          <label class="mr-4">
            Auto refresh
          </label>
        </div>
      </div>
      <div v-if="!standalone">
        <button @click="$emit('close')" class="text-lg p-2">
          <fa-icon :icon="['fas', 'times']" />
        </button>
      </div>
    </div>
    <div
      class="relative"
      :class="{ 'flex shadow-lg rounded overflow-hidden': horizontal }"
    >
      <plotly
        v-if="readings"
        :layout="layouts.temperature"
        :data="plots.temperatures"
        class="flex-1"
      />
      <plotly
        v-if="readings"
        :layout="layouts.humidity"
        :data="plots.humidities"
        class="flex-1"
      />
      <div
        v-if="processing"
        class="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white z-50 opacity-50"
      >
        <fa-icon
          :icon="['fas', 'spinner-third']"
          class="text-3xl text-blue-500 fa-spin fa-fw"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Plotly } from 'vue-plotly';
import dayjs from 'dayjs';

export default {
  components: { Plotly },
  props: {
    location: {
      type: Object,
      required: true
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    standalone: {
      type: Boolean,
      default: false
    },
    defaultInterval: {
      type: String,
      default: 'today'
    }
  },
  data() {
    return {
      timezone: 'UTC',
      interval: this.defaultInterval,
      intervals: [
        { value: 'today', name: 'Today' },
        { value: 'last-3-hours', name: 'Last 3 Hours' },
        { value: 'last-24-hours', name: 'Last 24 Hours' },
        { value: 'last-7-days', name: 'Last 7 Days' },
        { value: 'last-30-days', name: 'Last 30 Days' },
        { value: 'last-90-days', name: 'Last 90 Days' },
        { value: 'all', name: 'All' }
      ],
      readings: [],
      sensors: [],
      refreshInterval: null,
      processing: false,
      autoRefresh: true
    };
  },
  watch: {
    interval() {
      this.getReadings();
    }
  },
  mounted() {
    this.timezone = dayjs.tz.guess();
    this.getReadings();

    this.refreshInterval = setInterval(() => {
      if (this.autoRefresh) {
        this.getReadings();
      }
    }, 15000);
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
  },
  computed: {
    plots() {
      const sensors = this.readings.reduce((result, reading) => {
        result[reading.sensor_id] = [
          ...(result[reading.sensor_id] || []),
          reading
        ];

        return result;
      }, {});

      const temperatures = [];
      const humidities = [];
      Object.keys(sensors).forEach(sensorId => {
        const temperatureX = [];
        const temperatureY = [];
        const humidityX = [];
        const humidityY = [];

        sensors[sensorId].forEach(reading => {
          const date = dayjs
            .utc(reading.recorded_at)
            .local()
            .format();
          temperatureX.push(date);
          temperatureY.push(reading.temperature);

          humidityX.push(date);
          humidityY.push(reading.humidity);
        });

        const sensor = this.sensors.find(
          sensor => sensor.id === parseInt(sensorId)
        );
        temperatures.push({
          x: temperatureX,
          y: temperatureY,
          type: 'scatter',
          name: sensor.name
        });

        humidities.push({
          x: humidityX,
          y: humidityY,
          type: 'scatter',
          name: sensor.name
        });
      });

      return {
        temperatures,
        humidities
      };
    },
    layouts() {
      const temperature = {
        title: 'Temperature',
        shapes: [
          {
            type: 'line',
            x0: 0,
            y0: this.location.min_temperature,
            x1: 1,
            y1: this.location.min_temperature,
            xref: 'paper',
            line: {
              color: 'red',
              width: 1.5,
              dash: 'dot'
            }
          },
          {
            type: 'line',
            x0: 0,
            y0: this.location.max_temperature,
            x1: 1,
            y1: this.location.max_temperature,
            xref: 'paper',
            line: {
              color: 'red',
              width: 1.5,
              dash: 'dot'
            }
          }
        ]
      };
      const humidity = {
        title: 'Humidity',
        shapes: [
          {
            type: 'line',
            x0: 0,
            y0: this.location.min_humidity,
            x1: 200,
            y1: this.location.min_humidity,
            xref: 'paper',
            line: {
              color: 'red',
              width: 1.5,
              dash: 'dot'
            }
          },
          {
            type: 'line',
            x0: 0,
            y0: this.location.max_humidity,
            x1: 200,
            y1: this.location.max_humidity,
            xref: 'paper',
            line: {
              color: 'red',
              width: 1.5,
              dash: 'dot'
            }
          }
        ]
      };

      return {
        temperature,
        humidity
      };
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async getReadings() {
      this.processing = true;
      try {
        const query = `timezone=${this.timezone}&interval=${this.interval}`;
        const { data } = await this.axios.get(
          `/locations/${this.location.id}/readings?${query}`
        );

        this.sensors = data.sensors;
        this.readings = data.readings;
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>

<style>
.modebar-group {
  display: flex !important;
}
</style>
