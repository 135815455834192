<template>
  <div id="app" class="flex flex-col antialiased min-h-screen">
    <primary-navbar v-if="$store.state.auth.loggedIn" :links="links" />
    <div class="flex flex-1">
      <transition name="sidebar">
        <sidebar-navbar v-if="sidebarIsVisible" :links="links" />
      </transition>
      <div class="bg-gray-200 p-8 flex-1">
        <transition
          :name="transition"
          mode="out-in"
          @beforeLeave="beforeLeave"
          @enter="enter"
          @afterEnter="afterEnter"
        >
          <router-view />
        </transition>
      </div>
    </div>
    <portal-target name="modals" class="z-50" />

    <notifications group="app" position="top center" class="mt-4" />
  </div>
</template>

<script>
import PrimaryNavbar from '@/components/global/navigation/PrimaryNavbar';
import SidebarNavbar from '@/components/global/navigation/SidebarNavbar';

export default {
  components: { SidebarNavbar, PrimaryNavbar },
  data() {
    return {
      transition: 'slide-right',
      sidebarTransition: 'open-sidebar',
      prevHeight: 0,
      links: [
        {
          name: 'Dashboard',
          path: '/',
          icon: ['fas', 'tachometer']
        },
        {
          name: 'Mating',
          path: '/mating',
          icon: ['fas', 'egg'],
          overview: false,
          submenus: [
            {
              name: 'Fly Rooms',
              path: '/mating/fly-rooms'
            },
            { name: 'Egg Batches', path: '/mating/egg-batches' }
          ]
        },
        {
          name: 'Hatching',
          path: '/hatching',
          icon: ['fas', 'microchip'],
          overview: false,
          submenus: [
            {
              name: 'Active Processes',
              path: '/hatching'
            },
            {
              name: 'Completed Processes',
              path: '/hatching/completed-processes'
            },
            {
              name: 'Feeding boxes',
              path: '/hatching/feeding-boxes'
            }
          ]
        },
        {
          name: 'Breeding',
          path: '/breeding',
          icon: ['fas', 'ball-pile'],
          overview: true
        },
        {
          name: 'Harvesting',
          path: '/harvesting',
          icon: ['fas', 'layer-group']
          // overview: false,
          // submenus: [
          //
          // ]
        },
        {
          name: 'Settings',
          path: '/settings',
          icon: ['fas', 'cog'],
          overview: 'General',
          submenus: [
            {
              name: 'Hatching Systems',
              path: '/settings/hatching-systems'
            },
            {
              name: 'Carts',
              path: '/settings/carts'
            },
            // {
            //   name: 'Fly Boxes',
            //   path: '/settings/fly-boxes'
            // },
            {
              name: 'Feeding Box Dimensions',
              path: '/settings/feeding-box-dimensions'
            },
            {
              name: 'Climate Control',
              path: '/settings/climate-control'
            },

            {
              name: 'Users',
              path: '/settings/users',
              superAdmin: true
            }
          ]
        }
      ]
    };
  },
  computed: {
    sidebarIsVisible() {
      if (!this.$store.state.auth.loggedIn) {
        return false;
      }

      return !['Dashboard', 'SetPassword'].includes(this.$route.name);
    }
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    }
  }
};
</script>

<style>
/* purgecss start ignore */
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.sidebar-enter-active,
.sidebar-leave-active {
  transition: all 0.3s;
}

.sidebar-enter,
.sidebar-leave-to {
  transform: translateX(-100%);
  /*@apply -translate-x-full;*/
}

.sidebar-enter-to,
.sidebar-leave {
  transform: translateX(0%);
}
</style>
