<template>
  <img v-if="src" :src="src" alt="Optinatura Feed" />
</template>

<script>
export default {
  props: {
    iconOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    src() {
      if (this.iconOnly) {
        return process.env.VUE_APP_ICON;
      }
      return process.env.VUE_APP_LOGO;
    }
  }
};
</script>
