export default [
  {
    path: '/harvesting',
    component: () =>
      import(
        /* webpackChunkName: "harvesting" */ '@/views/harvesting/index.vue'
      ),
    children: [
      {
        path: '',
        name: 'Harvesting',
        component: () =>
          import(
            /* webpackChunkName: "harvesting" */ '@/views/harvesting/HarvestingOverview.vue'
          )
      }
    ]
  }
];
