<template>
  <div class="flex justify-center items-center my-4">
    <hr class="flex-1 border-gray-200" />
    <div
      class="text-gray-800 uppercase leading-loose tracking-wide text-xs font-bold mx-2"
    >
      {{ label }}
    </div>
    <hr class="flex-1 border-gray-200" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    }
  }
};
</script>
