<template>
  <div>
    <div class="relative rounded border">
      <label
        class="absolute text-gray-600 block ml-2 input-label pointer-events-none"
        :class="classes"
      >
        {{ label }}
      </label>
      <div class="flex items-center">
        <input
          :value="value"
          @input="e => $emit('input', e.target.value)"
          :type="type"
          class="p-3 w-full rounded"
          :class="{ empty: !this.value }"
          @blur="focused = false"
          @focus="focused = true"
        />
        <button
          v-if="['date', 'time'].includes(type) && this.value"
          @click.stop="$emit('input', null)"
          class="absolute px-2 right-0 mr-10"
        >
          <fa-icon :icon="['fas', 'times']" class="text-gray-500 fa-fw" />
        </button>
      </div>
    </div>
    <p v-if="error" class="text-xs text-red-500 mt-1">{{ error }}</p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    error: {
      type: String,
      default: null
    },
    moveLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    labelAbove() {
      return !!this.value || this.value === 0;
    },
    classes() {
      if (!this.moveLabel) {
        return this.value || this.focused
          ? 'hidden'
          : 'px-2 py-3 text-sm z-10 w-full h-full';
      }

      return this.labelAbove || this.focused
        ? '-mt-2 text-xs px-1 bg-white'
        : 'mt-3 text-sm';
    }
  }
};
</script>

<style scoped>
.input-label {
  transition: 0.3s all;
}

input[type='date'].empty {
  color: transparent;
}

input[type='date']:focus {
  color: black !important;
}

input[type='time'].empty {
  color: transparent;
}

input[type='time']:focus {
  color: black !important;
}
</style>
