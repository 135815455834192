<template>
  <button
    @click="onClick"
    :disabled="disabled"
    class="font-bold text-white rounded px-4 py-2 transition-all duration-300"
    :class="_classes"
  >
    <fa-icon v-if="loading" :icon="['fas', 'spinner-third']" class="fa-spin" />
    <slot v-else>Submit</slot>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: 'bg-primary-400 hover:bg-primary-500 active:bg-primary-600'
    }
  },
  computed: {
    _classes() {
      if (this.disabled) {
        return 'bg-gray-200 text-gray-500';
      }
      return this.classes;
    }
  },
  methods: {
    onClick() {
      if (this.processing || this.disabled) {
        return;
      }

      this.$emit('click');
    }
  }
};
</script>
