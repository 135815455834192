<template>
  <nav class="lg:flex relative shadow">
    <div class="flex justify-between items-center px-4 py-2">
      <app-logo icon-only class="h-10 md:mr-40" />
      <button
        class="p-2 text-gray-500 focus:outline-none active:bg-gray-800 transition-all duration-300 lg:hidden"
        @click="isOpen = !isOpen"
      >
        <fa-icon v-if="isOpen" :icon="['fas', 'times']" class="fa-fw" />
        <fa-icon v-else :icon="['fas', 'bars']" class="fa-fw" />
      </button>
    </div>
    <div
      :class="isOpen ? 'block' : 'hidden'"
      class="font-bold text-gray-600 p-4 absolute w-full bg-white shadow md:shadow-none lg:relative lg:block z-30"
    >
      <navbar-link
        v-for="link in accessibleLinks"
        :key="link.name"
        :link="link"
        class="primary-navbar"
        :class="{ 'dashboard-link': link.name === 'Dashboard' }"
        @close="isOpen = false"
      />
      <hr class="my-4 border-gray-300 block lg:hidden" />
      <logout-button class="block lg:hidden" />
    </div>
    <logout-button class="hidden lg:inline-block" />
  </nav>
</template>

<script>
import NavbarLink from '@/components/global/navigation/NavbarLink';
import LogoutButton from '@/components/global/auth/LogoutButton';

export default {
  components: { LogoutButton, NavbarLink },
  props: {
    links: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    accessibleLinks() {
      if (this.$route.name === 'SetPassword') {
        return [];
      }

      if (this.$store.state.auth.user.is_super_admin) {
        return this.links;
      }

      return this.links.filter(link => link?.superAdmin !== true);
    }
  }
};
</script>

<style lang="scss">
.primary-navbar:not(.dashboard-link) {
  .active-link {
    @apply text-primary-500;

    svg {
      @apply text-primary-500;
    }
  }
}

.dashboard-link {
  .active-link {
    @apply text-primary-500;
  }
}

.active-link {
  pointer-events: none;
}
</style>
