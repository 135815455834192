<script>
import qs from 'qs';
import debounce from 'lodash.debounce';
import LoaderOverlay from '@/components/global/LoaderOverlay';
import SelectField from '@/components/global/form/SelectField';
import { handleFailedResponse } from '@/core/helpers';

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      default: null
    },
    include: {
      type: String,
      default: null
    },
    disablePagination: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      response: null,
      meta: null,
      loading: true,
      page: this.$route.query.page,
      per_page: this.$route.query.per_page
    };
  },
  created() {
    this.fetchJson();
  },
  watch: {
    filter: {
      handler: debounce(function() {
        this.fetchJson();
      }, 300),
      deep: true
    }
  },
  render(h) {
    const content = h('div', { class: 'relative' }, [
      h(
        'div',
        {
          class: 'transition-all duration-300',
          style: { filter: this.loading ? 'blur(2px)' : 'blur(0)' }
        },
        [
          this.$scopedSlots.default({
            response: this.response,
            loading: this.loading,
            reload: this.fetchJson,
            deleteResource: this.deleteResource
          })
        ]
      ),
      h(LoaderOverlay, {
        class: {
          'absolute left-0 top-0': true,
          'opacity-75 visible': this.loading,
          'opacity-0 invisible': !this.loading
        }
      })
    ]);

    const header = h('div', { class: 'mb-4' }, this.$slots.header);

    const perPageSelector = h(SelectField, {
      class: 'bg-white w-32 rounded shadow-md mr-2',
      props: {
        value: this.per_page,
        label: 'Per page',
        optionLabel: 'value',
        moveLabel: false,
        reduce: option => option.value,
        fixedOptions: [{ value: '5' }, { value: '10' }, { value: '20' }]
      },
      on: {
        input: value => {
          this.per_page = value;
          this.fetchJson();
        }
      }
    });

    const prevButton = h(
      'button',
      {
        class: {
          'px-4 py-2 bg-white shadow-md rounded text-gray-700 mr-2': true,
          'bg-gray-300 opacity-75 pointer-events-none': this.page === 1
        },
        attrs: {
          disabled: this.page === 1
        },
        on: {
          click: this.prev
        }
      },
      'prev'
    );

    const nextButton = h(
      'button',
      {
        class: {
          'px-4 py-2 bg-white shadow-md rounded text-gray-700': true,
          'bg-gray-300 opacity-75 pointer-events-none':
            this.meta && this.page === this.meta.last_page
        },
        attrs: {
          disabled: this.meta && this.page === this.meta.last_page
        },
        on: {
          click: this.next
        }
      },
      'next'
    );

    let controls = null;

    if (this.$slots.controls || !this.disablePagination) {
      controls = h(
        'div',
        { class: 'flex items-center justify-between text-sm mb-4' },
        [
          this.$slots.controls || h('div'),
          !this.disablePagination
            ? h('div', { class: 'flex' }, [
                perPageSelector,
                prevButton,
                nextButton
              ])
            : null
        ]
      );
    }

    return h('div', [header, controls, content]);
  },
  methods: {
    fetchJson(page = 1) {
      this.loading = true;

      this.$router.replace({
        query: {
          page,
          per_page: this.per_page,
          filter: qs.stringify(this.filter)
        }
      });

      this.axios
        .get(this.url, {
          params: {
            page,
            per_page: this.per_page,
            filter: this.filter,
            include: this.include
          },
          paramsSerializer: params => qs.stringify(params)
        })
        .then(response => {
          this.response = response.data.data;
          this.meta = response.data.meta;
          this.page = response.data?.meta?.current_page;
        })
        .catch(handleFailedResponse)
        .finally(() => (this.loading = false));
    },
    deleteResource(id) {
      this.loading = true;

      return this.axios
        .delete(`${this.url}/${id}`)
        .catch(handleFailedResponse)
        .finally(() => (this.loading = false));
    },
    prev() {
      const page = this.meta.current_page - 1;

      this.fetchJson(page);
    },
    next() {
      const page = this.meta.current_page + 1;

      this.fetchJson(page);
    }
  }
};
</script>
