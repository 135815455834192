export default [
  {
    path: '/settings',
    component: () =>
      import(/* webpackChunkName: "settings" */ '@/views/settings/index.vue'),
    children: [
      {
        path: '',
        name: 'Settings',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/settings/GeneralSettings.vue'
          )
      },
      {
        path: 'hatching-systems',
        name: 'HatchingSystems',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/settings/HatchingSystems.vue'
          )
      },
      {
        path: 'carts',
        name: 'Carts',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/settings/Carts.vue'
          )
      },
      // {
      //   path: 'fly-boxes',
      //   name: 'FlyBoxes',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "settings" */ '@/views/settings/FlyBoxes.vue'
      //     )
      // },
      {
        path: 'feeding-box-dimensions',
        name: 'FeedingBoxDimensions',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/settings/FeedingBoxDimensions.vue'
          )
      },
      {
        path: 'climate-control',
        name: 'ClimateControl',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/settings/ClimateControl.vue'
          )
      },
      {
        path: 'users',
        name: 'users',
        meta: {
          superAdmin: true
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/settings/Users.vue'
          )
      }
    ]
  }
];
