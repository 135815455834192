import isEmpty from 'lodash.isempty';
import Errors from './errors.js';

class Form {
  constructor(data, { axios }) {
    this.originalData = data;
    this.axios = axios;

    for (const field in data) {
      this[field] = data[field];
    }

    this.errors = new Errors();
    this.isSubmitting = false;
  }

  reset() {
    for (const field in this.originalData) {
      this[field] = null;
    }

    this.errors.clear();
  }

  data() {
    const data = Object.assign({}, this);

    delete data.originalData;
    delete data.errors;
    delete data.isSubmitting;

    return data;
  }

  submit(requestType, url, params = {}) {
    return new Promise((resolve, reject) => {
      if (this.isSubmitting) {
        return;
      }

      let requestData = Object.assign({}, this.data());

      if (!isEmpty(params.data)) {
        requestData = Object.assign(requestData, params.data);
      }

      this.isSubmitting = true;

      this.errors.clear();

      this.axios({
        url: url,
        method: requestType,
        data: requestData,
        baseURL: params.baseURL || '/api'
      })
        .then(response => {
          this.onSuccess(response.data);

          resolve(response.data);
        })
        .catch(error => {
          this.onFail(error.response.data);

          reject(error);
        })
        .finally(() => (this.isSubmitting = false));
    });
  }

  onSuccess() {
    this.errors.clear();
  }

  onFail({ errors }) {
    if (!isEmpty(errors)) {
      this.errors.record(errors);
    }
  }
}

export default {
  install(Vue, options = {}) {
    Vue.prototype.$form = data => {
      return new Form(data, options);
    };
  }
};
