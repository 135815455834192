<template>
  <div>
    <section-title class="mb-4">Dashboard</section-title>

    <div v-if="data" class="flex mb-8">
      <data-card class="w-1/5 mr-8" label="Active Processes" icon="play">
        {{ data.count.active_hatching_process }}
      </data-card>
      <data-card class="w-1/5 mr-8" label="Total processes" icon="layer-group">
        {{ data.count.total_hatching_process }}
      </data-card>
      <data-card
        class="w-1/5 mr-8"
        label="No of Hatching System"
        icon="download"
      >
        {{ data.count.hatching_system }}
      </data-card>
      <data-card class="w-1/5 mr-8" label="Total egg batches" icon="ball-pile">
        {{ data.count.egg_batch }}
      </data-card>
      <data-card class="w-1/5" label="Feeding box" icon="boxes">
        {{ data.count.feeding_box }}
      </data-card>
    </div>

    <climate-control-chart-section class="mb-8" />

    <h3 class="font-bold mb-4">Reports</h3>
    <div
      v-for="report in reports"
      :key="report.name"
      @click="showReport(report)"
      class="inline-block cursor-pointer shadow-md rounded bg-white px-4 py-8 mr-8 last:mr-0 border-2 border-transparent hover:border-primary-200 hover:bg-primary-100 hover:border-primary-500 transition-all duration-300"
    >
      <fa-icon :icon="['fas', 'download']" class="text-gray-500 text-xl mr-2" />
      {{ report.name }}
    </div>

    <div
      class="p-2 border border-yellow-500 rounded text-sm mt-8 bg-yellow-100 shadow"
    >
      Detected Timezone: {{ timezone }}
      (Note: All dates are stored in UTC will be formatted according to detected
      timezone)
    </div>

    <modal-dialog :show="showReportDialog">
      <div v-if="report">
        <h3 class="font-bold text-lg mb-4">
          Download {{ report.name }} report
        </h3>
        <input-field
          type="date"
          v-model="filter.from_date"
          label="From date (optional)"
          class="mb-4"
        />
        <input-field
          type="date"
          v-model="filter.to_date"
          label="To date (optional)"
          class="mb-4"
        />
        <div class="text-center">
          <div class="mb-4">
            <primary-button @click="downloadReport">
              Download report
            </primary-button>
          </div>
          <button @click="showReportDialog = false">close</button>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import DataCard from '@/components/dashboard/DataCard';
import { handleFailedResponse } from '@/core/helpers';
import dayjs from 'dayjs';
import ClimateControlChartSection from '../components/climate-control/ClimateControlChartSection';

export default {
  name: 'Dashboard',
  components: {
    ClimateControlChartSection,
    DataCard
  },
  mounted() {
    this.fetchData();
    this.timezone = dayjs.tz.guess();
  },
  data() {
    return {
      data: null,
      timezone: 'UTC',
      report: null,
      showReportDialog: false,
      filter: {
        from_date: null,
        to_date: null
      },
      reports: [
        { name: 'Counting Process', url: '/report/counting-process' },
        { name: 'Feeding Box', url: '/report/feeding-box' },
        { name: 'Larvae Count', url: '/report/larvae-count' },
        { name: 'Sensor Readings', url: '/report/sensor-readings' }
      ]
    };
  },
  methods: {
    async fetchData() {
      try {
        const { data } = await this.axios.get('/dashboard');

        this.data = data.data;
      } catch (e) {
        handleFailedResponse(e);
      }
    },
    showReport(report) {
      this.report = report;
      this.showReportDialog = true;
    },
    downloadReport() {
      const filters = [];

      if (this.filter.from_date) {
        filters.push(`filter[from_date]=${this.filter.from_date}`);
      }
      if (this.filter.to_date) {
        filters.push(`filter[to_date]=${this.filter.to_date}`);
      }

      let query = `timezone=${this.timezone}`;

      if (filters.length > 0) {
        query = query + '&' + filters.join('&');
      }

      window.open(`${this.report.url}?${query}`);
    }
  }
};
</script>
