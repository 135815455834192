<template>
  <div
    class="sidebar-navbar bg-primary-500 hidden lg:block w-0 lg:w-48 transition-all duration-300"
  >
    <div v-for="link in visibleLinks" :key="link.name">
      <router-link
        :to="link.path"
        class="text-primary-100 transition-all duration-300 font-bold block p-4"
        :class="{ 'active-link': $route.path === link.path }"
      >
        {{ link.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      submenus: []
    };
  },
  created() {
    this.submenus = this.getSubmenus();
  },
  computed: {
    visibleLinks() {
      const links = this.submenus.filter(submenu =>
        this.$route.fullPath.startsWith(submenu.parent)
      );

      if (this.$store.state.auth.user.is_super_admin) {
        return links;
      }

      return links.filter(link => link?.superAdmin !== true);
    }
  },
  methods: {
    getSubmenus() {
      const links = [];

      this.submenus = this.links.map(link => {
        if (!link.submenus) {
          return null;
        }

        if (link.overview) {
          links.push({
            name:
              typeof link.overview === 'string' ||
              link.overview instanceof String
                ? link.overview
                : 'Overview',
            path: link.path,
            parent: link.path
          });
        }

        const submenus = link.submenus.map(submenu => {
          submenu.parent = link.path;
          return submenu;
        });

        links.push(...submenus);
      });

      return links;
    }
  }
};
</script>

<style lang="scss">
.sidebar-navbar {
  @apply text-sm;

  .active-link {
    @apply bg-primary-800 text-white;
  }
}
</style>
