import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import PortalVue from 'portal-vue';
import './common';
import '@/assets/scss/index.scss';
import Form from '@/core/form';
import './fontawesome';
import VueClipboard from 'vue-clipboard2';
import Echo from './echo';
import Notifications from 'vue-notification';
import '@/plugins/dayjs';

Vue.config.productionTip = false;
axios.defaults.baseURL = '/api';
axios.defaults.withCredentials = true;

Vue.use(VueAxios, axios);
Vue.use(PortalVue);
Vue.use(Form, { axios });
Vue.use(VueClipboard);
Vue.use(Echo, { axios });
Vue.use(Notifications);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
