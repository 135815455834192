<template>
  <button
    class="whitespace-no-wrap text-gray-600 font-bold p-2 md:my-4 md:px-4 mr-4 hover:bg-primary-100 rounded hover:text-primary-400"
    @click="logout"
  >
    <fa-icon :icon="['fas', 'power-off']" class="text-gray-400 mr-1 fa-fw" />
    Logout
  </button>
</template>

<script>
export default {
  methods: {
    async logout() {
      await this.axios({ url: '/logout', baseURL: '/', method: 'delete' });
      this.$store.commit('LOGOUT');
      await this.$router.push({ name: 'Login' });
    }
  }
};
</script>
